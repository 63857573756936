import FormCadastro from 'src/components/FormCadastro/FormCadastro.vue'

export default {
  name: 'Cadastro',
  components: { FormCadastro },
  meta () {
    return {
      title: 'Cadastro',
      description: { name: 'description', content: 'Faça o seu cadastro aqui na Netcarros' },
      property: { 'name': 'og:image', content: 'https://www.netcarros.com.br/seo/netcarros-financiamento.jpg' },
      meta: [
        { 'property': 'og:title', 'content': 'Cadastro' },
        { 'name': 'description', 'content': 'Faça o seu cadastro aqui na Netcarros' },
        { 'property': 'revisit-after', 'content': '5 days' },
        { 'property': 'og:image', 'content': 'https://www.netcarros.com.br/seo/netcarros-cadastro.jpg' },
        { 'property': 'og:image:secure_url', 'content': 'https://www.netcarros.com.br/seo/netcarros-cadastro.jpg' },
        { 'property': 'og:url', 'content': 'https://www.netcarros.com.br' + this.$route.fullPath },
        { 'property': 'og:site_name', 'content': 'Netcarros' },
        { 'property': 'fb:admins', 'content': '', 'vmid': 'fb:admins' },
        { 'property': 'og:locale', 'content': 'pt_BR' }
      ]
    }
  }
}
